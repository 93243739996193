import { Button } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ModalAdvertising = ({
  noneHandler = undefined,
  yesHandler = undefined,
  loading = false,
  title = '',
  text = '',
  buttonYesText = '',
}) => {
  ModalAdvertising.propTypes = {
    noneHandler: PropTypes.func,
    yesHandler: PropTypes.func,
    loading: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    buttonYesText: PropTypes.string,
  };
  const { t } = useTranslation();
  const b = bem('modalEventsDelete');

  return (
    <div data-testid='elem-modal-delete' className={b()}>
      <div>
        <h1 data-testid='title_delete_modal'>{title}</h1>
        <p style={{ color: '#7e7e7e' }}>{text}</p>
        <div className='btn_delete'>
          <Button
            data-testid='elem-not'
            onClick={noneHandler}
            style={{
              backgroundColor: '#F4F6FF',
              color: '#007AFF',
              marginRight: '12px',
            }}
          >
            {t('challenges.cancel')}
          </Button>
          <Button
            loading={loading}
            data-testid='yes-elem'
            onClick={yesHandler}
            style={{
              backgroundColor: '#007AFF',
              color: '#fff',
            }}
          >
            {buttonYesText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalAdvertising;
