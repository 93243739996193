import { RightOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import logoutIcons from 'assets/images/iconsMenu/logout.svg';
import logo from 'assets/images/logo/Logo.svg';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalExit from 'components/UI/ModalExit/ModalExit';
import { clearResultsChallenges } from 'redux/challengesSlice/challengesSlice';
import {
  historyNotificationsSelector,
  linksAddReducer,
  modalExitReducer,
} from 'redux/historyNotifications/historyNotifications';
import { clearResultsPromotions } from 'redux/promotions/promotionsSlice';
import {
  searchReducer,
  searchSelector,
  searchTermPromoReducer,
  searchTermReducer,
  searchTermStartReducer,
} from 'redux/search/searchSlice';
import { clearStarts } from 'redux/startsSlice/startsSlice';
import { navigation } from 'utils/constants';

const Navigation = ({
  logOutHandler = undefined,
  isModalVisible = undefined,
  yesDeleteHandler = undefined,
  noneDeleteHandler = undefined,
}) => {
  Navigation.propTypes = {
    logOutHandler: PropTypes.func,
    isModalVisible: PropTypes.bool,
    yesDeleteHandler: PropTypes.func,
    noneDeleteHandler: PropTypes.func,
  };
  const b = bem('MenuNavigation');
  const { t } = useTranslation();
  const [openKeys, setOpenKeys] = useState(['/']);
  const { errorsValidate } = useSelector(historyNotificationsSelector);
  const { searchTerm, searchTermPromo, searchTermStart } = useSelector(searchSelector);
  const dispatch = useDispatch();
  const push = useNavigate();
  const { pathname } = useLocation();
  const [current, setCurrent] = useState('/');
  const link = pathname.split('/').filter((i) => i)[0];

  useEffect(() => {
    const link = pathname.split('/').filter((i) => i)[0];
    setCurrent(link ? `/${link}` : '/');
  }, [pathname]);

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const menuHandler = (e) => {
    const value = e.key;
    setCurrent(value);
    if (errorsValidate) {
      dispatch(modalExitReducer(true));
      dispatch(linksAddReducer(value));
    } else {
      push(value);
      if (value.includes('/challenges') && searchTermPromo.length) {
        dispatch(clearResultsPromotions());
        dispatch(searchTermPromoReducer(''));
      } else if (value.includes('/starts') && searchTermStart.length) {
        dispatch(clearStarts());
        dispatch(searchTermStartReducer(''));
      } else if (value.includes('/promotions') && searchTerm.length) {
        dispatch(clearResultsChallenges());
        dispatch(searchTermReducer(''));
      } else if (
        value.includes('/admin') &&
        value.length === 1 &&
        (searchTerm.length || searchTermPromo.length)
      ) {
        dispatch(clearResultsChallenges());
        dispatch(searchTermReducer(''));
        dispatch(clearResultsPromotions());
        dispatch(searchTermPromoReducer(''));
      }
    }
    dispatch(searchReducer(''));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const rootSubmenuKeys = [
    '/',
    '/news',
    'rootChallenges',
    'rootPromotions',
    'rootStarts',
    '/teams',
  ];

  const menuItems = navigation.map((item) => {
    const children = item.children?.map((child) =>
      getItem(t(`navigation.${child.name}`), child.link, child.icons),
    );
    return getItem(t(`navigation.${item.name}`), item.link, item.icons, children);
  });

  return (
    <div className={b()}>
      <div className={b('logo_nomad')}>
        <Link to='/admin'>
          <img src={logo} alt='logo_nomad' />
        </Link>
      </div>
      <div className={b('navigation_menu')}>
        <div className={b('link_navigation')}>
          <Menu
            style={{ background: '#130e2f', padding: '0 10px' }}
            mode='inline'
            theme='dark'
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            items={menuItems}
            onClick={menuHandler}
            defaultSelectedKeys={['/']}
            selectedKeys={current.includes(link) ? [current] : ['/']}
          />
        </div>
      </div>
      <button type='button' onClick={logOutHandler} className={b('logout')}>
        <div className='logout_flex'>
          <div className='logout_img'>
            <img src={logoutIcons} alt='logout-icons' />
          </div>
          <p>{t('navigation.log_out')}</p>
        </div>

        <RightOutlined
          style={{ color: '#c1bae5', margin: '7px 15px', fontSize: 13 }}
          size='small'
        />
      </button>
      <ModalComponent open={isModalVisible} width={440} height='100%'>
        <ModalExit yesDeleteHandler={yesDeleteHandler} noneDeleteHandler={noneDeleteHandler} />
      </ModalComponent>
    </div>
  );
};

export default Navigation;
