import Card from 'antd/lib/card/Card';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ButtonsHandlers from 'components/ButtonsHandlers/ButtonsHandlers';
import CardInfo from 'components/CardInfo/CardInfo';
import Errors from 'components/Errors/Errors';
import SkeletonInfo from 'components/SkeletonBlock/SkeletonInfo/SkeletonInfo';
import TableComponent from 'components/TableComponent/TableComponent';
import TeamsTable from 'components/TeamsTable/TeamsTable';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import ModalDelete from 'components/UI/ModalDelete/ModalDelete';
import {
  deleteHistoryNewsNull,
  deleteNewsSelector,
  newsDeleteFetch,
  newsFetch,
  newsInfoFetch,
  newsInfoSelector,
  newsSelector,
  updateNewsNavigateNull,
} from 'redux/news/newsSlice';

const Info = () => {
  const b = bem('Info');
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { results, loading, error } = useSelector(newsInfoSelector);
  const push = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { news } = useSelector(newsSelector);
  const { history, loadingDelete } = useSelector(deleteNewsSelector);
  const resultsObj = news.find((item) => item.id === +id) || results;

  useEffect(() => {
    if (!news.length) {
      dispatch(newsInfoFetch({ id }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (history === 'success') {
      const data = {
        query: { page: 1 },
      };
      push('/news');
      dispatch(
        newsFetch({
          data,
        }),
      );
    }

    dispatch(deleteHistoryNewsNull());
    dispatch(updateNewsNavigateNull());
  }, [dispatch, history, push]);

  const updateHandler = () => {
    dispatch(updateNewsNavigateNull());
    push(`/news/update-news/${id}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const deleteNews = () => {
    dispatch(newsDeleteFetch({ id }));
  };

  if (loading) {
    return <SkeletonInfo />;
  }

  if (error) {
    return <Errors status={error} />;
  }

  return (
    <div className={b()} data-testid='news-info__element'>
      <div>
        <div className={b('card')}>
          <CardInfo resultsInfo={resultsObj} pushHandler='/news' componentName='news.name' />
        </div>
        <Card className={b('block_btn')}>
          <ButtonsHandlers
            nameChange='news'
            hasActiveInactive={false}
            updateHandler={updateHandler}
            modalDeleteHandler={() => setIsModalVisible(true)}
            textEdit={t('news.edit_news')}
            textDelete={t('news.delete_news')}
          />
        </Card>
      </div>
      {resultsObj?.target ? (
        <div className={b('table')}>
          <TableComponent hasSearch={false} isTeams={false} />
          <TeamsTable hasSearch={false} />
        </div>
      ) : null}

      <ModalComponent
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width={443}
        height='100%'
      >
        <ModalDelete
          title={t('news.modal_delete')}
          text={t('news.modal_delete_text')}
          noneDeleteHandler={() => setIsModalVisible(false)}
          yesDeleteHandler={deleteNews}
          loading={loadingDelete}
        />
      </ModalComponent>
    </div>
  );
};

export default Info;
