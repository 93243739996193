import { Button, Card, Form, message, Typography } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';

import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import DownloadPhoto from 'components/DownloadPhoto/DownloadPhoto';
import Errors from 'components/Errors/Errors';
import FormField from 'components/FormField/FormField';
import Loading from 'components/Loading/Loading';
import { messagesSuccess } from 'components/Messages/Messagetext';
import {
  errorsValidateNullReducer,
  errorsValidateReducer,
} from 'redux/historyNotifications/historyNotifications';
import {
  deletePhotosHttpsNews,
  deleteUpdateNewsPhoto,
  editNewsAddReducer,
  editNewsReducer,
  newsInfoFetch,
  newsInfoSelector,
  newsSelector,
  newsUpdate,
  newsUpdatePhotoDownload,
  updatePutNews,
} from 'redux/news/newsSlice';
import { checkHandlerFor, filesValidateImg } from 'utils/constants';
import { successText } from 'utils/errorsHandler/errorsHandler';
import filesValidate from 'utils/filesValidate/filesValidate';
import localesUtils from 'utils/localesUtils/localesUtils';
import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';
import { updateNewsValidate } from 'utils/updateValidate/updateValidate';

const { Title } = Typography;

const Update = () => {
  const b = bem('Update');
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const push = useNavigate();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [validateForm, setValidateForm] = useState(false);
  const { editNews, updateNewsData, news } = useSelector(newsSelector);
  const { loading, updatePutNewsNavigate } = useSelector(updatePutNews);
  const { results, loading: newsInfoLoading, errors } = useSelector(newsInfoSelector);
  const resultsObj = news.find((item) => item.id === +id) || results;

  useEffect(() => {
    if (!news.length) {
      dispatch(newsInfoFetch({ id }));
    }
  }, [dispatch, results?.length, id]);

  useEffect(() => {
    if (resultsObj) {
      dispatch(editNewsAddReducer(resultsObj));
    }
  }, [dispatch, resultsObj]);

  useEffect(() => {
    if (resultsObj) {
      form.setFieldsValue({
        title: resultsObj?.title,
        text: resultsObj?.text,
      });
    }
  }, [form, resultsObj]);

  useEffect(() => {
    if (updatePutNewsNavigate) {
      history(`/news/news-info/${id}/`);
      message.success(messagesSuccess(successText('updateNews')));
    }
  }, [updatePutNewsNavigate]);

  useEffect(() => {
    const resultsUpdate = resultsObj;
    if (resultsUpdate) {
      const validate = updateNewsValidate(resultsUpdate, editNews, editNews.deleted_images);
      setValidateForm(validate);
      if (validate) {
        dispatch(errorsValidateNullReducer());
      } else {
        dispatch(errorsValidateReducer(true));
      }
    }
  }, [editNews, updateNewsData]);

  const formHandler = (e) => {
    const { target } = e;
    const dataName = target.dataset.name;
    dispatch(editNewsReducer({ [dataName]: target.value }));
    if (target.value) {
      dispatch(errorsValidateReducer(true));
    }
  };

  const photosSaveHandler = (e) => {
    const { files } = e.target;
    if (filesValidateImg(files).length) {
      const ret = filesValidate(files, editNews.images);
      checkHandlerFor(newsUpdatePhotoDownload, ret);
    }
  };

  const deletePhotoHandler = (id) => {
    dispatch(deleteUpdateNewsPhoto(id));
  };

  const deletePhotoHttps = (id) => {
    dispatch(deletePhotosHttpsNews(id));
  };

  const onFinish = () => {
    const editObj = {
      ...editNews,
    };
    delete editObj.images;
    delete editObj.deleted_images;

    if (editNews?.images.length) {
      if (updateNewsData.imagesHttps.length) {
        editObj[`images[0]id`] = updateNewsData.imagesHttps[0].id;
      }
      editNews.images.forEach((item, i) => {
        editObj[`images[${i}]image`] = item;
      });
    }

    if (editNews.deleted_images?.length && !editNews.images.length) {
      editNews.deleted_images.forEach((item, i) => {
        editObj[`deleted_images[${i}]`] = item;
      });
    }

    const formData = new FormData();
    for (const name in editObj) {
      if (name) {
        formData.append(name, editObj[name]);
      }
    }

    const data = {
      newsId: id,
      newData: formData,
    };

    dispatch(newsUpdate(data));
  };

  if (newsInfoLoading) {
    return <Loading />;
  }

  if (errors) {
    return <Errors status={errors} />;
  }

  return (
    <div className={b('update-style-calc')} data-testid='update-news-form'>
      <Form form={form} name='basic' layout='vertical' onChange={formHandler} onFinish={onFinish}>
        <Card bordered className={b('update-promotion-form-images')}>
          <BreadcrumbsComponent
            linkProps
            textEnd={t('news.edit_news')}
            pushHandler='/news/'
            componentName='news.name'
          />
          <Title strong level={3} style={{ margin: 0, lineHeight: 'normal' }}>
            {t('news.edit_news')}
          </Title>
          <Title disabled level={5} style={{ margin: '5px 0 15px 0', fontWeight: 'normal' }}>
            {t('news.edit_your_news')}
          </Title>
          <DownloadPhoto
            isNews
            photos={[...updateNewsData.images, ...editNews.images]}
            maxPhotos={0}
            deletePhotoHandler={deletePhotoHandler}
            photosSaveHandler={photosSaveHandler}
            deletePhotoHttps={deletePhotoHttps}
            title={false}
          />
          <FormField
            type='modificationInput'
            label='news.news_title'
            name='title'
            message={localesUtils(
              'Пожалуйста, введите название новости',
              'Please input your news title',
            )}
            rules={[
              {
                max: 40,
                message: localesValidateInput(
                  <span>
                    {localesUtils(
                      'Название не должно содержать больше 40 символов',
                      'Title should be less than 40 symbols',
                    )}
                  </span>,
                  'promotionsDescriptionSecond',
                  localesUtils(
                    'Название не должно содержать больше 40 символов',
                    'Title should be less than 40 symbols',
                  ),
                ),
              },
            ]}
            dataName='title'
            placeholder='news.news_title'
          />
          <FormField
            type='modificationInput'
            maxLength={150}
            label='news.news_description'
            name='text'
            message={localesUtils(
              'Пожалуйста, введите описание новости',
              'Please input your news text',
            )}
            rules={[
              {
                max: 150,
                message: localesValidateInput(
                  <span>
                    {localesUtils(
                      'Описание не должно содержать больше 150 символов',
                      'Text should be less than 150 symbols',
                    )}
                  </span>,
                  'promotionsDescriptionSecond',
                  localesUtils(
                    'Описание не должно содержать больше 150 символов',
                    'Text should be less than 150 symbols',
                  ),
                ),
              },
            ]}
            dataName='text'
            placeholder='news.news_description'
          />
          <div className={b('button-save')}>
            <Button
              className={b('button-update button-cancel')}
              onClick={() => push(`/news/news-info/${id}`)}
            >
              {t('promotions.cancel')}
            </Button>
            <Button
              loading={loading}
              disabled={validateForm}
              style={{ backgroundColor: validateForm ? 'rgb(137, 186, 239)' : '#007AFF' }}
              data-testid='button-update-submit'
              onClick={() => {
                form.submit();
              }}
              type='primary'
              className={b('button-update button-save')}
            >
              {t('promotions.save')}
            </Button>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default Update;
