import bem from 'easy-bem';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router';
import { Routes, useLocation } from 'react-router-dom';

import PageNotFound from 'components/Errors/PageNotFound/PageNotFound';
import Header from 'components/Header/Header';
import MenuNavigation from 'components/MenuNavigation/MenuNavigation';
import UploadPhotoComponents from 'components/UploadPhotoComponents/UploadPhotoComponents';
import AdvertisingChallenges from 'containers/Challenges/AdvertisingChallenges/AdvertisingChallenges';
import Challenges from 'containers/Challenges/Challenges';
import Conditions from 'containers/Challenges/Create/Conditions/Conditions';
import CreatingChallenges from 'containers/Challenges/Create/Сreating/Сreating';
import ChallengeInfo from 'containers/Challenges/Info/Info';
import ChallengeUpdate from 'containers/Challenges/Update/Update';
import Home from 'containers/Home/Home';
import CreateNews from 'containers/News/Create/Creating';
import NewsInfo from 'containers/News/Info/Info';
import News from 'containers/News/News';
import UpdateNews from 'containers/News/Update/Update';
import AdvertisingPromotions from 'containers/Promotions/AdvertisingPromotions/AdvertisingPromotions';
import Creating from 'containers/Promotions/Create/Creating/Creating';
import PromotionInfo from 'containers/Promotions/Info/Info';
import Promotions from 'containers/Promotions/Promotions';
import PromotionUpdate from 'containers/Promotions/Update/Update';
import SearchResults from 'containers/SearchResults/SearchResults';
import AdvertisingStarts from 'containers/Starts/AdvertisingStarts/AdvertisingStarts';
import CreatingStarts from 'containers/Starts/Create/Сreating/Creating';
import Info from 'containers/Starts/Info/Info';
import Starts from 'containers/Starts/Starts';
import Update from 'containers/Starts/Update/Update';
import CreateTeams from 'containers/Teams/Create/Creating/Creating';
import Members from 'containers/Teams/Create/Members/Members';
import TeamsInfo from 'containers/Teams/Info/Info';
import Teams from 'containers/Teams/Teams';
import UpdateTeams from 'containers/Teams/Update/Update';
import { errorsValidateNullReducer } from 'redux/historyNotifications/historyNotifications';
import errorsPush from 'utils/errorsPush/errorsPush';

const AppRouter = () => {
  const b = bem('AppRouter');
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!errorsPush(pathname)) {
      window.onbeforeunload = false;
      dispatch(errorsValidateNullReducer());
    }
  }, [pathname]);

  return (
    <div data-testid='router-elem' className={b()}>
      <div>
        <MenuNavigation />
      </div>
      <div className={b('routers')}>
        <div>
          <Header />
        </div>
        <div className='route_components'>
          <div className='containers'>
            <Routes>
              <Route path='/admin' element={<Home />} />
              <Route path='/promotions/' element={<Promotions />} />
              <Route path='/promotions/promotion-info/:id/' element={<PromotionInfo />} />
              <Route path='/promotions/create-promotion' element={<Creating />} />
              <Route
                path='/promotions/create-promotion/UploadPhoto'
                element={
                  <UploadPhotoComponents
                    type='promotions'
                    linkTobe='promotions.creating_promotion'
                    linkPush='/promotions/create-promotion'
                    text='promotions.create_your_promotions'
                  />
                }
              />
              <Route path='/promotions/update-promotion/:id/' element={<PromotionUpdate />} />
              <Route path='/challenges' element={<Challenges />} />
              <Route path='/challenges/challenge-info/:id' element={<ChallengeInfo />} />
              <Route path='/challenges/creatingChallenges' element={<CreatingChallenges />} />
              <Route
                path='/challenges/creatingChallenges/challengeConditions'
                element={<Conditions />}
              />
              <Route
                path='/challenges/creatingChallenges/challengeConditions/UploadPhoto'
                element={
                  <UploadPhotoComponents
                    type='challenges'
                    linkTobe='challenges.creating_challenges'
                    linkPush='/challenges/creatingChallenges'
                    text='challenges.creating_challenges_photo'
                  />
                }
              />
              <Route path='/challenges/update-challenge/:id' element={<ChallengeUpdate />} />
              <Route path='/search-results' element={<SearchResults />} />
              <Route path='/starts' element={<Starts />} />
              <Route path='/starts/starts-info/:id' element={<Info />} />
              <Route path='/starts/update-starts/:id' element={<Update />} />
              <Route path='/starts/create' element={<CreatingStarts />} />
              <Route path='/starts' element={<Starts />} />
              <Route path='/starts/starts-info/:id' element={<Info />} />
              <Route path='/starts/update-starts/:id' element={<Update />} />
              <Route path='/starts/create' element={<CreatingStarts />} />
              <Route
                path='/starts/create/uploadPhotos'
                element={
                  <UploadPhotoComponents
                    type='starts'
                    linkTobe='starts.creating_starts'
                    linkPush='/starts/create'
                    text='starts.upload_photo'
                  />
                }
              />
              <Route path='/teams' element={<Teams />} />
              <Route path='/teams/teams-info/:id' element={<TeamsInfo />} />
              <Route path='/teams/create' element={<CreateTeams />} />
              <Route path='/teams/create/teamMembers' element={<Members />} />
              <Route path='/teams/update-teams/:id' element={<UpdateTeams />} />
              <Route path='/news' element={<News />} />
              <Route path='/news/create' element={<CreateNews />} />
              <Route path='/news/news-info/:id' element={<NewsInfo />} />
              <Route path='/news/update-news/:id' element={<UpdateNews />} />
              <Route path='/advertising-challenges' element={<AdvertisingChallenges />} />
              <Route path='/advertising-promotions' element={<AdvertisingPromotions />} />
              <Route path='/advertising-starts' element={<AdvertisingStarts />} />
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppRouter;
