import {
  HolderOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Select } from 'antd';
import bem from 'easy-bem';

import PropTypes from 'prop-types';

import React from 'react';

import { useTranslation } from 'react-i18next';
import 'components/AdvertisingHeader/_advertisingHeader.scss';

import localesUtils from 'utils/localesUtils/localesUtils';

const AdvertisingHeader = ({
  title = '',
  hasCategories = false,
  categories = [],
  modeActive = 1,
  changeMode = () => {},
  changeDragMode = () => {},
  cancelDrag = () => {},
  dragMode = false,
}) => {
  AdvertisingHeader.propTypes = {
    title: PropTypes.string,
    statusItems: PropTypes.instanceOf(Array),
    hasCategories: PropTypes.bool,
    categories: PropTypes.instanceOf(Array),
    modeActive: PropTypes.number,
    changeMode: PropTypes.func,
    changeDragMode: PropTypes.func,
    cancelDrag: PropTypes.func,
    dragMode: PropTypes.bool,
  };

  const b = bem('AdvertisingHeader');
  const { t } = useTranslation();

  const items = [
    {
      key: '2',
      label: (
        <button type='button' className='AdvertisingHeader__select_block'>
          {t('challenges.all')}
        </button>
      ),
      icon: <HolderOutlined />,
    },
    {
      key: '0',
      label: (
        <button type='button' className='AdvertisingHeader__select_block'>
          {t('challenges.active')}
        </button>
      ),
      icon: <PlusCircleOutlined />,
    },
    {
      key: '1',
      label: (
        <button type='button' className='AdvertisingHeader__select_block'>
          {t('challenges.inactive')}
        </button>
      ),
      icon: <MinusCircleOutlined />,
    },
  ];

  const categoriesItems = [
    {
      key: 'vse',
      label: (
        <button type='button' className='status_block_categories'>
          <div className='icons_categories_block'>
            <UnorderedListOutlined
              style={{
                padding: 3,
                color: 'black',
                fontSize: 12,
              }}
            />
          </div>
          <p> {t('challenges.all')} </p>
        </button>
      ),
    },
    ...categories.map((item) => {
      return {
        key: item.id,
        label: (
          <button type='button' className='status_block_categories'>
            <div className='icons_categories_block'>
              <img
                src={item.icon}
                width={18}
                height={18}
                className='black_category'
                alt={item.name_en}
              />
            </div>
            <p>{localesUtils(item.name_ru, item.name_en)}</p>
          </button>
        ),
      };
    }),
  ];

  return (
    <div className={b()}>
      <h2 className={b('title')}>{t(title)}</h2>
      <div className={`${b('header_block')} ${dragMode ? 'single_child' : ''}`}>
        {!dragMode ? (
          <div>
            <p className={b('filter_title')}>{t('advertising.filter')}</p>
            <div className={b('flex')}>
              <Select className={b('filter')} placeholder={t('home.statuses')}>
                {items.map((item) => (
                  <Select.Option key={item.key} className={b('option')}>
                    {item.icon}
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
              {hasCategories ? (
                <Select className={b('filter')} placeholder={t('challenges.categories')}>
                  {categoriesItems?.map((item) => (
                    <Select.Option key={item.key} className={b('option')}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className={b('flex')}>
          <div className={b('mode_select')}>
            <button
              type='button'
              className={modeActive === 1 ? 'mode_button_active' : ''}
              onClick={() => changeMode(1)}
            >
              {t('advertising.cards')}
            </button>
            <button
              type='button'
              className={modeActive === 2 ? 'mode_button_active' : ''}
              onClick={() => changeMode(2)}
            >
              {t('advertising.list')}
            </button>
          </div>
          {dragMode ? (
            <Button
              type='primary'
              style={{
                color: '#200E32',
                backgroundColor: 'rgba(0, 122, 255, 0.1)',
              }}
              onClick={cancelDrag}
            >
              {t('challenges.cancel')}
            </Button>
          ) : null}
          <Button type='primary' style={{ fontWeight: 'bold' }} onClick={changeDragMode}>
            {dragMode ? t('advertising.save_order') : t('advertising.change_order')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdvertisingHeader;
