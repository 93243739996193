import { StarFilled } from '@ant-design/icons';
import { Card } from 'antd';
import bem from 'easy-bem';

import PropTypes from 'prop-types';
import React from 'react';

import ReactHtmlParser from 'react-html-parser';

import { useTranslation } from 'react-i18next';

import notPhoto from 'assets/images/notPhotos.png';
import photoPlug from 'assets/images/photo-plug.png';
import FormField from 'components/FormField/FormField';
import { dateEnd, dateFormat } from 'utils/dateFormat/dateFormat';

import navigatorUserAgent from 'utils/navigatorUserAgent/navigatorUserAgent';
import { optionsActiveInactive } from 'utils/sortStatus/sortStatus';
import 'components/DragAndDrop/AdvertCard/_advertCard.scss';

const AdvertCard = ({ item, dragMode, handleModalOpen }) => {
  AdvertCard.propTypes = {
    item: PropTypes.instanceOf(Object),
    dragMode: PropTypes.bool,
    handleModalOpen: PropTypes.func,
  };
  const b = bem('AdvertCard');
  const { t } = useTranslation();

  return (
    <div data-testid='card_list' key={item.id} className={b('block_card')}>
      <div key={item.id} className='block_card_text btn_block_style_none'>
        <Card
          hoverable
          className={b('card_advert')}
          style={dragMode ? { cursor: 'grab' } : { cursor: 'pointer' }}
          cover={
            item.images?.length ? (
              <img
                style={{
                  height: 190,
                }}
                alt='example'
                draggable={false}
                src={item.images[0]?.image ? item?.images[0].image : notPhoto}
              />
            ) : (
              <img
                style={{
                  height: 190,
                }}
                alt='example'
                src={photoPlug}
              />
            )
          }
          bodyStyle={{
            position: 'relative',
            marginTop: '-35px',
            left: -1,
            background: '#fff',
            width: '346px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          <div className='block_card d-block'>
            <p className='card_title title_card_hidden'>{item.title}</p>
            <div className='card_text text_card_hidden'>{ReactHtmlParser(item.description)}</div>
          </div>
          <div className={`block_run ${navigatorUserAgent()}_scroll`}>
            {dateEnd(item.starts_at) || dateEnd(item.ends_at) ? (
              <div className='block_text'>
                <p className='run_title'>{t('challenges.willStart')}</p>
                <p className='run_text'>{dateFormat(item.starts_at)}</p>
              </div>
            ) : null}
            {!dateEnd(item.starts_at) && dateEnd(item.ends_at) ? (
              <div className='block_text'>
                <p className='run_title'>{t('challenges.ended')}</p>
                <p className='run_text'>{dateFormat(item.ends_at)}</p>
              </div>
            ) : null}
            {!dateEnd(item.starts_at) && !dateEnd(item.ends_at) ? (
              <div className='block_text'>
                <p className='run_title'>{t('challenges.ended')}</p>
                <p className='run_text'>{t('challenges.finished')}</p>
              </div>
            ) : null}
          </div>
          <div className={b('bottom_block')}>
            <span className={b('order')}>{item.order}</span>
            {item.category ? (
              <div className={b('category')}>
                <div>
                  <img src={item.category.icon} width={24} height={24} alt={item.category.name} />
                </div>
                <p>{item.category.name}</p>
              </div>
            ) : null}
            <div className={b('block_category_active')}>
              <FormField
                disabled={!dateEnd(item.ends_at)}
                type='selectStatus'
                options={optionsActiveInactive}
                size={20}
                value={item.status}
                id={item.id}
              />
            </div>
            {!dragMode ? (
              <button type='button' className={b('star')} onClick={() => handleModalOpen(item.id)}>
                <StarFilled />
              </button>
            ) : null}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AdvertCard;
