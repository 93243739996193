import { Button } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Errors from 'components/Errors/Errors';
import PlugForLists from 'components/NoChallenge/plugForLists';
import NotActiveInactive from 'components/NotActiveInactive/NotActiveInactive';
import NotFoundSearch from 'components/NotFoundSearch/NotFoundSearch';
import ShowAllCard from 'components/ShowAllCard/ShowAllCard';
import SkeletonBlock from 'components/SkeletonBlock/SkeletonBlock';
import SortBy from 'components/SortBy/SortBy';
import SortCategory from 'components/SortBy/SortCategory/SortCategory';
import ModalActiveInactive from 'components/UI/ModalActiveInactive/ModalActiveInactive';
import ModalAdvertising from 'components/UI/ModalAdvertising/ModalAdvertising';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import {
  activeErrorsLoader,
  activeInactiveFetch,
  addPageParams,
  addResultsInfo,
  challengesCategoriesFetch,
  challengesFetch,
  challengesSelector,
  creatingNullReducer,
  loadingChallengesActiveInActive,
  minusPageParams,
  nullPageParams,
  paginationSelector,
  sortHandlerReducer,
  updateHistory,
} from 'redux/challengesSlice/challengesSlice';
import { searchReducer, searchSelector } from 'redux/search/searchSlice';
import localesUtils from 'utils/localesUtils/localesUtils';
import { optionsActiveInactive, textActiveInactive } from 'utils/sortStatus/sortStatus';

const Challenges = () => {
  const b = bem('Challenges');
  const { t } = useTranslation();
  const push = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataActiveInactive, setDataActiveInactive] = useState({
    value: '',
    id: 0,
  });
  const { results, errors, loading, sort, categories, params, activeInactive, searchTxt } =
    useSelector(challengesSelector);
  const { next, previous } = useSelector(paginationSelector);
  const { searchTerm } = useSelector(searchSelector);
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (params.page < 1) {
      dispatch(nullPageParams());
    }
  }, [params]);

  useEffect(() => {
    const data = {
      query: { page: params.page || 1 },
    };
    if (!searchTerm && !results.length) {
      dispatch(
        challengesFetch({
          data,
          sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
          name: searchTerm ? `&name=${searchTerm}` : searchTerm,
        }),
      );
    }
  }, [dispatch, searchTerm, searchTxt]);

  useEffect(() => {
    if (!categories.results.length) {
      dispatch(challengesCategoriesFetch());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(nullPageParams());
  }, [searchTerm]);

  useEffect(() => {
    const findBool = results.find((item) => Number(item.id) === Number(dataActiveInactive.id));
    if (findBool) {
      if (Number(findBool?.status) === dataActiveInactive.value) {
        setIsModalVisible(false);
      }
    }
  }, [results]);

  useEffect(() => {
    const findBool = results.find((item) => Number(item.id) === Number(dataActiveInactive.id));
    if (findBool) {
      if (Number(findBool?.status) === dataActiveInactive.value) {
        const data = {
          query: { page: 1 },
        };
        dispatch(
          challengesFetch({
            data,
            sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
            name: searchTerm ? `&name=${searchTerm}` : searchTerm,
          }),
        );
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (activeInactive.errors) {
      setIsModalVisible(false);
      dispatch(activeErrorsLoader(dataActiveInactive.id));
    }
  }, [activeInactive, dataActiveInactive]);

  const onChange = (value, id) => {
    setIsModalVisible(true);
    setDataActiveInactive({
      value,
      id,
    });
  };

  const fileHandler = () => {
    dispatch(creatingNullReducer());
    dispatch(searchReducer(''));
  };

  const pushHandler = () => {
    dispatch(creatingNullReducer());
    push('/challenges/creatingChallenges');
  };

  const categoriesHandler = ({ key }) => {
    const data = {
      query: { page: params.page },
    };
    if (Number(params.page) >= 1) {
      data.query.page = 1;
      dispatch(nullPageParams());
    }

    if (categories.results.length) {
      if (key !== 'vse') {
        const titleText = categories.results.find((item) => item.id === +key);
        dispatch(
          challengesFetch({
            data,
            sort: `&sort=status,-id${sort.status.url}&category=${key}`,
            name: searchTerm ? `&name=${searchTerm}` : searchTerm,
          }),
        );
        dispatch(
          sortHandlerReducer({
            categories: {
              id: key,
              title: {
                name_ru: `«${titleText.name_ru}»`,
                name_en: `«${titleText.name_en}»`,
              },
              url: `&category=${key}`,
            },
          }),
        );
      } else {
        dispatch(
          challengesFetch({
            data,
            sort: `&sort=status,-id${sort.status.url}`,
            name: searchTerm ? `&name=${searchTerm}` : searchTerm,
          }),
        );
        dispatch(
          sortHandlerReducer({
            categories: {
              id: 'vse',
              title: {
                name_ru: '',
                name_en: '',
              },
              url: '',
            },
          }),
        );
      }
    }
  };

  const handlerNotPush = () => {
    push('/challenges/creatingChallenges');
    dispatch(creatingNullReducer());
  };

  const statusHandler = ({ key }) => {
    const data = {
      query: { page: params.page },
    };
    if (Number(params.page) >= 1) {
      data.query.page = 1;
      dispatch(nullPageParams());
    }
    if (Number(key) <= 1) {
      dispatch(
        challengesFetch({
          data,
          sort: `&sort=status,-id&status=${key}${sort.categories.url}`,
          name: searchTerm ? `&name=${searchTerm}` : searchTerm,
        }),
      );
      dispatch(
        sortHandlerReducer({
          status: {
            id: key,
            title: Number(key) === 0 ? 'sort.active' : 'sort.inactive',
            url: `&status=${key}`,
          },
        }),
      );
    } else {
      dispatch(
        challengesFetch({
          data,
          sort: `&sort=status,-id${sort.categories.url}`,
          name: searchTerm ? `&name=${searchTerm}` : searchTerm,
        }),
      );
      dispatch(
        sortHandlerReducer({
          status: {
            id: '2',
            title: '',
            url: '',
          },
        }),
      );
    }
  };

  const nextBrowserChallengesHandler = (id) => {
    push(`/challenges/challenge-info/${id}`);
    const findHome = results.find((item) => item.id === Number(id));
    if (findHome) {
      dispatch(addResultsInfo(findHome));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(searchReducer(''));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const yesHandler = () => {
    const statusUpdate = {
      status: dataActiveInactive.value,
    };
    dispatch(activeInactiveFetch({ data: statusUpdate, id: dataActiveInactive.id }));
    dispatch(loadingChallengesActiveInActive({ id: dataActiveInactive.id }));
    dispatch(updateHistory());
  };

  const nextHandler = () => {
    dispatch(addPageParams());
    const data = {
      query: { page: params.page + 1 || 1 },
    };
    dispatch(
      challengesFetch({
        data,
        sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
        name: searchTerm ? `&name=${searchTerm}` : searchTerm,
      }),
    );
  };

  const backHandler = () => {
    dispatch(minusPageParams());
    const data = {
      query: { page: params.page - 1 || 1 },
    };
    dispatch(
      challengesFetch({
        data,
        sort: `&sort=status,-id${sort.status.url}${sort.categories.url}`,
        name: searchTerm ? `&name=${searchTerm}` : searchTerm,
      }),
    );
  };

  const handleModalOpen = (id) => {
    setModalOpen(true);
  };

  const closeHandler = () => {
    setModalOpen(false);
  };

  const renderSearchText = () => {
    if (searchTerm) {
      return (
        <>
          {t('search.search_challenges')} {t(sort.status.title)}
          {sort.categories.title.name_ru ? (
            <span className='text_categories_name'>
              {' '}
              {localesUtils(sort.categories.title.name_ru, sort.categories.title.name_en)}
            </span>
          ) : null}
        </>
      );
    }
    return (
      <>
        {t(sort.status.title)} {t('challenges.name')}
        {sort.categories.title.name_ru ? (
          <span className='text_categories_name'>
            {' '}
            {localesUtils(sort.categories.title.name_ru, sort.categories.title.name_en)}
          </span>
        ) : null}
      </>
    );
  };

  const renderTextSort = () => {
    if (searchTerm) {
      return (
        <>
          {t('search.search_challenges')} {t(sort.status.title)}
          {sort.categories.title.name_ru ? (
            <span className='text_categories_name'>
              {' '}
              {localesUtils(sort.categories.title.name_ru, sort.categories.title.name_en)}
            </span>
          ) : null}
        </>
      );
    }
    return (
      <>
        {t(sort.status.title)} {t('challenges.name')}
        {sort.categories.title.name_ru ? (
          <span className='text_categories_name'>
            {' '}
            {localesUtils(sort.categories.title.name_ru, sort.categories.title.name_en)}
          </span>
        ) : null}
      </>
    );
  };

  if (loading || categories.loading) {
    return <SkeletonBlock active={loading || categories.loading} num={20} titleBool />;
  }

  if (errors || categories.errors) {
    return <Errors status={errors || categories.errors} />;
  }

  if (searchTerm && !results.length) {
    return (
      <div className={b()}>
        <div className={b('block_d_flex_categories')}>
          <p className='title_text_v'>
            {t('search.search_challenges')} {t(sort.status.title).toLowerCase()}
            {sort.categories.title.name_ru ? (
              <span className='text_categories_name'>
                {' '}
                {localesUtils(sort.categories.title.name_ru, sort.categories.title.name_en)}
              </span>
            ) : null}
          </p>
          <div>
            <SortBy
              statusHandler={statusHandler}
              titleObject={sort}
              categoriesHandler={categoriesHandler}
              menuBoolean
            />
            <SortCategory
              menu={categories.results}
              loading={categories.loading}
              categoriesHandler={categoriesHandler}
              titleObject={sort}
            />
          </div>
        </div>
        <NotFoundSearch textSearch={searchTerm} />
      </div>
    );
  }

  if (
    (sort.status.title && !results.length) ||
    (sort.categories.title.name_ru && !results.length)
  ) {
    return (
      <div className={b()}>
        <div className={b('block_d_flex_categories')}>
          <p className='title_text_v'>{renderTextSort()}</p>
          <div>
            <SortBy
              statusHandler={statusHandler}
              titleObject={sort}
              categoriesHandler={categoriesHandler}
              menuBoolean
            />
            <SortCategory
              menu={categories.results}
              loading={categories.loading}
              categoriesHandler={categoriesHandler}
              titleObject={sort}
            />
          </div>
        </div>

        <NotActiveInactive
          message={` ${textActiveInactive(sort.status.id)} ${t(
            'challenges.name_text',
          )} ${localesUtils(sort.categories.title.name_ru, sort.categories.title.name_en)} ${t(
            'challenges.no',
          )}`}
          handlerNotPush={handlerNotPush}
          textLink={t('challenges.creating_challenges')}
        />
      </div>
    );
  }

  if (results.length) {
    return (
      <div className={b()}>
        <div className={b('block_d_flex_categories')}>
          <p className='title_text_v'>{renderSearchText()}</p>
          <div>
            <SortBy
              statusHandler={statusHandler}
              titleObject={sort}
              categoriesHandler={categoriesHandler}
              menuBoolean
            />
            <SortCategory
              menu={categories.results}
              loading={categories.loading}
              categoriesHandler={categoriesHandler}
              titleObject={sort}
            />
          </div>
        </div>
        <ShowAllCard
          link='/challenges/creatingChallenges'
          fileLoading
          results={results || []}
          options={optionsActiveInactive}
          onChange={onChange}
          fileHandler={fileHandler}
          category
          status
          requirements
          dataDisableEnd
          nextBrowserHandler={nextBrowserChallengesHandler}
          titleFile={t('challenges.create_new_challenges')}
          textFile={t('challenges.create_your_challenges')}
          handleModalOpen={handleModalOpen}
        />
        <div className={b('pagination_btn')}>
          <Button
            className='btn_Challenges'
            type='primary'
            size='large'
            disabled={previous === null}
            onClick={() => backHandler()}
          >
            {t('promotions.back')}
          </Button>
          <Button
            className='btn_Challenges'
            type='primary'
            size='large'
            disabled={next === null}
            onClick={() => nextHandler()}
          >
            {t('promotions.next')}
          </Button>
        </div>
        <ModalComponent open={isModalVisible} onCancel={handleCancel} width={420} height='100%'>
          <ModalActiveInactive
            results={results?.find((item) => Number(item.id) === Number(dataActiveInactive.id))}
            noneHandler={() => setIsModalVisible(false)}
            yesHandler={yesHandler}
          />
        </ModalComponent>
        <ModalComponent onCancel={closeHandler} open={isModalOpen} width={443} height='100%'>
          <ModalAdvertising
            title={t('advertising.want_to_add_challenge')}
            text={t('advertising.challenge_will_be_advertising')}
            buttonYesText={t('challenges.add')}
            noneHandler={closeHandler}
            yesHandler={closeHandler}
          />
        </ModalComponent>
      </div>
    );
  }

  return (
    <PlugForLists
      pushHandler={pushHandler}
      subMainText={t('challenges.creating_challenges_photo')}
      buttonText={t('challenges.creating_challenges')}
    />
  );
};

export default Challenges;
