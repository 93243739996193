import { AppstoreFilled } from '@ant-design/icons';
import { Badge, Menu, message } from 'antd';
import React from 'react';

import alarmIcon from 'assets/images/icons/alarmIcon.svg';
import engIcons from 'assets/images/icons/English.svg';
import lightningIcon from 'assets/images/icons/lightningIcon.svg';
import newsIcon from 'assets/images/icons/newsIcon.svg';
import rusIcons from 'assets/images/icons/Russian.svg';
import starIcon from 'assets/images/icons/starIcon.svg';
import subMenuBlue from 'assets/images/icons/subMenuBlue.svg';
import subMenuPurple from 'assets/images/icons/subMenuPurple.svg';
import teamsIcon from 'assets/images/icons/teamsIcon.svg';
import challengesIcons from 'assets/images/iconsMenu/challenges.svg';
import homeIcons from 'assets/images/iconsMenu/home.svg';
import promotionsIcons from 'assets/images/iconsMenu/promotions.svg';
import startsIcons from 'assets/images/iconsMenu/starts.svg';
import BikeIcons from 'assets/images/statusIcons/Bike.svg';
import RunIcons from 'assets/images/statusIcons/Run.svg';
import SwimIcons from 'assets/images/statusIcons/Swim.svg';
import teamsIcons from 'assets/images/statusIcons/Team.svg';
import { messagesError } from 'components/Messages/Messagetext';
import store from 'redux/store';
import localesUtils from 'utils/localesUtils/localesUtils';

export const menu = (
  <Menu
    items={[
      {
        key: '1',
        label: (
          <button type='button' onClick={() => {}} className='Status__block_status'>
            <img src={RunIcons} alt='runIcons' />
          </button>
        ),
      },
      {
        key: '2',
        label: (
          <button type='button' onClick={() => {}} className='Status__block_status'>
            <img src={BikeIcons} alt='BikeIcons' />
          </button>
        ),
      },
      {
        key: '3',
        label: (
          <button type='button' onClick={() => {}} className='Status__block_status'>
            <img src={SwimIcons} alt='SwimIcons' />
          </button>
        ),
      },
    ]}
  />
);

export const statisticItems = [
  {
    title: 'total_number_of_strava_requests',
    dateName: 'March',
    summa: '24 000 000',
    interest: '15%',
    dateNameInterest: 'April',
    summa2: '11 000',
  },
  {
    title: 'number_of_strava_requests_in_the_last_15_minutes',
    dateName: 'March',
    summa: '24 000 000',
    interest: '15%',
    dateNameInterest: 'April',
    summa2: '11 000',
  },
];

export const menuText = [
  {
    name: 'home',
    link: '/admin',
    icons: homeIcons,
  },
  // {
  //   name: 'news',
  //   link: '/news',
  //   icons: startsIcons,
  // },
  {
    name: 'challenges',
    link: '/challenges',
    icons: challengesIcons,
  },
  {
    name: 'teams',
    link: '/teams',
    icons: teamsIcons,
  },
  {
    name: 'promotions',
    link: '/promotions',
    icons: promotionsIcons,
  },
  {
    name: 'starts',
    link: '/starts',
    icons: startsIcons,
  },
];

export const navigation = [
  {
    name: 'home',
    link: '/',
    icons: <AppstoreFilled />,
  },
  {
    name: 'news',
    link: '/news',
    icons: <img src={newsIcon} alt='News' />,
  },
  {
    name: 'challenges',
    link: 'rootChallenges',
    icons: <img src={lightningIcon} alt='Challenges' />,
    children: [
      {
        name: 'challenges',
        link: '/challenges',
        icons: <img src={subMenuPurple} alt='Challenges' />,
      },
      {
        name: 'advert_challenges',
        link: '/advertising-challenges',
        icons: <img src={subMenuBlue} alt='Challenges' />,
      },
    ],
  },
  {
    name: 'promotions',
    link: 'rootPromotions',
    icons: <img src={starIcon} alt='Promotions' />,
    children: [
      {
        name: 'promotions',
        link: '/promotions',
        icons: <img src={subMenuPurple} alt='Promotions' />,
      },
      {
        name: 'advert_promotions',
        link: '/advertising-promotions',
        icons: <img src={subMenuBlue} alt='Challenges' />,
      },
    ],
  },
  {
    name: 'starts',
    link: 'rootStarts',
    icons: <img src={alarmIcon} alt='Starts' />,
    children: [
      {
        name: 'starts',
        link: '/starts',
        icons: <img src={subMenuPurple} alt='Starts' />,
      },
      {
        name: 'advert_starts',
        link: '/advertising-starts',
        icons: <img src={subMenuBlue} alt='Starts' />,
      },
    ],
  },
  {
    name: 'teams',
    link: '/teams',
    icons: <img src={teamsIcon} alt='Teams' />,
  },
];

const userStatus = {
  inactive: 1,
  active: 2,
  blocked: 3,
  deleted: 4,
};

export const userStatusRoles = {
  [userStatus.inactive]: { tag: <Badge color='#FFC700' />, text: 'inactive' },
  [userStatus.active]: { tag: <Badge color='#47CE6D' />, text: 'active' },
  [userStatus.blocked]: { tag: <Badge color='#fa8c16' />, text: 'blocked' },
  [userStatus.deleted]: { tag: <Badge color='#FF4B55' />, text: 'deleted' },
};

const participantStatus = {
  accepted: 1,
  achieved: 2,
  failed: 3,
};

export const challengeUserStatusRoles = {
  [participantStatus.accepted]: { tag: <Badge color='#47CE6D' />, text: 'accepted' },
  [participantStatus.achieved]: { tag: <Badge color='#FFC700' />, text: 'achieved' },
  [participantStatus.failed]: { tag: <Badge color='#FF4B55' />, text: 'failed' },
};

export const userGender = {
  female: 1,
  male: 2,
};

export const userGenderDisplay = {
  [userGender.female]: 'female',
  [userGender.male]: 'male',
};

export const statusAccount = [
  { name: 'inactive', value: 1, color: '#FFC700' },
  { name: 'active', value: 2, color: '#47CE6D' },
  { name: 'blocked', value: 3, color: '#fa8c16' },
  { name: 'deleted', value: 4, color: '#FF4B55' },
];

export const stravaStatus = [
  { name: 'linked', value: true },
  { name: 'not_linked', value: false },
];

export const participationStatus = [
  { name: 'accepted', value: 1, color: '#47CE6D' },
  { name: 'achieved', value: 2, color: '#FFC700' },
  { name: 'failed', value: 3, color: '#FF4B55' },
];

export const userGenderRoles = [
  { name: 'female', value: 1 },
  { name: 'male', value: 2 },
];

export const iconsTable = {
  large: (size) => (
    <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.5 1C0.5 0.447715 0.947715 0 1.5 0H13.5C14.0523 0 14.5 0.447715 14.5 1V4H0.5V1Z'
        fill={size === 'large' ? '#007AFF' : '#9DABBB'}
      />
      <rect x='0.5' y='5' width='14' height='4' fill={size === 'large' ? '#007AFF' : '#9DABBB'} />
      <path
        d='M0.5 10H14.5V13C14.5 13.5523 14.0523 14 13.5 14H1.5C0.947716 14 0.5 13.5523 0.5 13V10Z'
        fill={size === 'large' ? '#007AFF' : '#9DABBB'}
      />
    </svg>
  ),
  middle: (size) => (
    <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.5 1C0.5 0.447715 0.947715 0 1.5 0H13.5C14.0523 0 14.5 0.447715 14.5 1V2H0.5V1Z'
        fill={size === 'middle' ? '#007AFF' : '#9DABBB'}
      />
      <rect x='0.5' y='3' width='14' height='2' fill={size === 'middle' ? '#007AFF' : '#9DABBB'} />
      <rect x='0.5' y='6' width='14' height='2' fill={size === 'middle' ? '#007AFF' : '#9DABBB'} />
      <rect x='0.5' y='9' width='14' height='2' fill={size === 'middle' ? '#007AFF' : '#9DABBB'} />
      <path
        d='M0.5 12H14.5V13C14.5 13.5523 14.0523 14 13.5 14H1.5C0.947716 14 0.5 13.5523 0.5 13V12Z'
        fill={size === 'middle' ? '#007AFF' : '#9DABBB'}
      />
    </svg>
  ),
  small: (size) => (
    <svg width='15' height='14' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.5 1C0.5 0.447715 0.947715 0 1.5 0H13.5C14.0523 0 14.5 0.447715 14.5 1H0.5Z'
        fill={size === 'small' ? '#007AFF' : '#9DABBB'}
      />
      <rect x='0.5' y='2' width='14' height='1' fill={size === 'small' ? '#007AFF' : '#9DABBB'} />
      <rect x='0.5' y='4' width='14' height='1' fill={size === 'small' ? '#007AFF' : '#9DABBB'} />
      <rect x='0.5' y='6' width='14' height='1' fill={size === 'small' ? '#007AFF' : '#9DABBB'} />
      <rect x='0.5' y='8' width='14' height='1' fill={size === 'small' ? '#007AFF' : '#9DABBB'} />
      <rect x='0.5' y='10' width='14' height='1' fill={size === 'small' ? '#007AFF' : '#9DABBB'} />
      <path
        d='M0.5 12H14.5C14.5 12.5523 14.0523 13 13.5 13H1.5C0.947716 13 0.5 12.5523 0.5 12Z'
        fill={size === 'small' ? '#007AFF' : '#9DABBB'}
      />
    </svg>
  ),
};

export const checkHandlerFor = (reducer, files) => {
  const index = store;
  files.forEach((item) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(item);
    img.onload = () => {
      const imageAspectRatio = img.width / img.height;
      if (imageAspectRatio.toFixed(1) === '1.8') {
        index.dispatch(reducer(item));
      } else {
        message.error(
          messagesError(
            localesUtils(
              'Пропорции фотографий должны быть 16:9 для корректного отображения в приложении',
              'Photo aspect ratio must be 16:9 to display correctly in the app',
            ),
          ),
        );
      }
    };
  });
};

export const filesValidateImg = (files = []) => {
  const filesCopy = [...files];
  return filesCopy.filter(
    (item) => item.type.includes('image/jpeg') || item.type.includes('image/png'),
  );
};

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.substring(1);
export const optionsChallenges = (num, searchChallenges) => {
  const resultsOptions = searchChallenges[0].options.filter((item, index) => index < num);
  return [
    {
      label: searchChallenges[0].label,
      options: resultsOptions,
    },
  ];
};

export const optionsCard = (num, searchChallenges) => {
  return searchChallenges.filter((item, index) => index < num);
};

export const optionsPromotions = (num, searchPromotions) => {
  const resultsOptions = searchPromotions[0].options.filter((item, index) => index < num);
  return [
    {
      label: searchPromotions[0].label,
      options: resultsOptions,
    },
  ];
};

export const optionsStarts = (num, searchStarts) => {
  const resultsOptions = searchStarts[0].options.filter((item, index) => index < num);
  return [
    {
      label: searchStarts[0].label,
      options: resultsOptions,
    },
  ];
};

export const optionsTeams = (num, searchTeams) => {
  const resultsOptions = searchTeams[0].options.filter((item, index) => index < num);
  return [
    {
      label: searchTeams[0].label,
      options: resultsOptions,
    },
  ];
};

export const checkSpaces = (str) => str.trim() !== '';

export const languages = [
  {
    name_en: 'English',
    name: 'Eng',
    code: 'en',
    img: engIcons,
    lang: 'English',
  },
  {
    name_en: 'Russian',
    name: 'Rus',
    code: 'ru',
    img: rusIcons,
    lang: 'Русский',
  },
];

export const deleteEmptyQueryStrings = (params) => {
  for (const key of Object.keys(params)) {
    if (params[key] === '' || params[key] === null || params[key] === undefined) {
      delete params[key];
    }
  }
  return params;
};

export const typeValidate = (type) => {
  if (type === 'challenges') {
    return '/challenges';
  }

  if (type === 'promotions') {
    return '/promotions/';
  }

  if (type === 'starts') {
    return '/starts';
  }
};
