import { Button } from 'antd';
import bem from 'easy-bem';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Errors from 'components/Errors/Errors';
import PlugForLists from 'components/NoChallenge/plugForLists';
import ShowAllCard from 'components/ShowAllCard/ShowAllCard';
import SkeletonBlock from 'components/SkeletonBlock/SkeletonBlock';
import {
  addPageParamsNews,
  clearCreateNews,
  minusPageParamsNews,
  newsFetch,
  newsSelector,
} from 'redux/news/newsSlice';

const News = () => {
  const b = bem('News');
  const { t } = useTranslation();
  const push = useNavigate();
  const dispatch = useDispatch();
  const { news, loading, errors, params, pagination } = useSelector(newsSelector);

  useEffect(() => {
    if (!news.length) {
      const data = {
        query: { page: params.page || 1 },
      };
      dispatch(newsFetch({ data }));
    }
  }, [dispatch]);

  const nextBrowserHandler = (id) => {
    push(`/news/news-info/${id}`);
  };

  const backHandler = () => {
    dispatch(minusPageParamsNews());
    const data = {
      query: { page: params.page - 1 || 1 },
    };
    dispatch(newsFetch({ data }));
  };

  const nextHandler = () => {
    dispatch(addPageParamsNews());
    const data = {
      query: { page: params.page + 1 || 1 },
    };
    dispatch(newsFetch({ data }));
  };

  const pushHandler = () => {
    dispatch(clearCreateNews());
    push('/news/create');
  };

  if (loading) {
    return <SkeletonBlock active={loading} num={20} titleBool />;
  }

  if (errors) {
    return <Errors status={errors} />;
  }

  if (news.length) {
    return (
      <div className={b()} data-testid='news-page'>
        <h1 className={b('title')}>{t('news.last_updated')}</h1>
        <ShowAllCard
          isNews
          link='/news/create'
          fileLoading
          results={news || []}
          onChange={() => {}}
          requirements
          fileHandler={() => dispatch(clearCreateNews())}
          nextBrowserHandler={nextBrowserHandler}
          titleFile={t('news.create_new_news')}
          textFile={t('news.create_news')}
        />
        <div className={b('pagination_btn')}>
          <Button
            className='btn_starts'
            type='primary'
            size='large'
            disabled={pagination.previous === null}
            onClick={() => backHandler()}
          >
            {t('promotions.back')}
          </Button>
          <Button
            className='btn_starts'
            type='primary'
            size='large'
            disabled={pagination.next === null}
            onClick={() => nextHandler()}
          >
            {t('promotions.next')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <PlugForLists
      pushHandler={pushHandler}
      subMainText={t('news.create_news')}
      buttonText={t('news.news_creation')}
    />
  );
};

export default News;
