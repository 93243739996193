import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  rectSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { Form, Radio, Table } from 'antd';
import bem from 'easy-bem';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import AdvertCard from 'components/DragAndDrop/AdvertCard/AdvertCard';
import SortableItem from 'components/DragAndDrop/SortableItem/SortableItem';
import SortableRow from 'components/DragAndDrop/SortableRow/SortableRow';
import 'components/DragAndDrop/_dragAndDrop.scss';
import { iconsTable } from 'utils/constants';

const DragAndDrop = ({ modeActive, handleDragEnd, dragMode, items, columns, handleModalOpen }) => {
  DragAndDrop.propTypes = {
    modeActive: PropTypes.number,
    dragMode: PropTypes.bool,
    handleDragEnd: PropTypes.func,
    items: PropTypes.instanceOf(Array),
    columns: PropTypes.instanceOf(Array),
    handleModalOpen: PropTypes.func,
  };

  const b = bem('DragAndDrop');
  const [size, setSize] = useState('large');

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  return (
    <div>
      {modeActive === 1 ? (
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToFirstScrollableAncestor]}
        >
          {dragMode ? (
            <SortableContext items={items} strategy={rectSortingStrategy}>
              <div className={b('dnd-container')}>
                {items.map((item) => (
                  <SortableItem key={item.id} item={item}>
                    <AdvertCard dragMode={dragMode} item={item} />
                  </SortableItem>
                ))}
              </div>
            </SortableContext>
          ) : (
            <div className={b('dnd-container')}>
              {items.map((item) => (
                <AdvertCard
                  key={item.id}
                  dragMode={dragMode}
                  item={item}
                  handleModalOpen={handleModalOpen}
                />
              ))}
            </div>
          )}
        </DndContext>
      ) : (
        <>
          <div className={b('sizes_block')}>
            <Form.Item style={{ margin: 0 }}>
              <Radio.Group value={size} onChange={handleSizeChange}>
                <div className={b('sizes_buttons')}>
                  <Radio.Button
                    style={{
                      border: 'none',
                      background: 'none',
                    }}
                    value='large'
                  >
                    <span className={size}>{iconsTable.large(size)}</span>
                  </Radio.Button>
                  <Radio.Button
                    style={{
                      border: 'none',
                      background: 'none',
                    }}
                    value='middle'
                  >
                    <span className={size}>{iconsTable.middle(size)}</span>
                  </Radio.Button>
                  <Radio.Button
                    style={{
                      border: 'none',
                      background: 'none',
                    }}
                    value='small'
                  >
                    <span className={size}>{iconsTable.small(size)}</span>
                  </Radio.Button>
                </div>
              </Radio.Group>
            </Form.Item>
          </div>
          <DndContext
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
          >
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
              <Table
                components={{
                  body: {
                    row: dragMode ? SortableRow : null,
                  },
                }}
                className={b('table')}
                stripe
                rowKey='id'
                columns={columns}
                dataSource={items}
                pagination={false}
                scroll={{
                  x: 950,
                }}
                size={size}
              />
            </SortableContext>
          </DndContext>
        </>
      )}
    </div>
  );
};

export default DragAndDrop;
