import { Button } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Errors from 'components/Errors/Errors';
import PlugForLists from 'components/NoChallenge/plugForLists';
import NotActiveInactive from 'components/NotActiveInactive/NotActiveInactive';
import NotFoundSearch from 'components/NotFoundSearch/NotFoundSearch';
import ShowAllCard from 'components/ShowAllCard/ShowAllCard';
import SkeletonBlock from 'components/SkeletonBlock/SkeletonBlock';
import SortBy from 'components/SortBy/SortBy';
import ModalAdvertising from 'components/UI/ModalAdvertising/ModalAdvertising';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import {
  addPageParamsPromotions,
  addResultsInfoPromotions,
  clearState,
  loadingActiveInactive,
  minusPageParamsPromotions,
  nullPageParamsPromotions,
  paginationPromotionsSelector,
  promotionsActiveInactive,
  promotionsFetch,
  promotionsSelector,
  sortHandlerPromotionReducer,
} from 'redux/promotions/promotionsSlice';
import { searchReducer, searchSelector } from 'redux/search/searchSlice';
import { optionsActiveInactive } from 'utils/sortStatus/sortStatus';

const Promotions = () => {
  const { t } = useTranslation();
  const b = bem('Promotions');
  const push = useNavigate();
  const dispatch = useDispatch();
  const {
    promotions,
    getPromotionsLoading,
    sort,
    getPromotionsError,
    params,
    promotionInfo,
    searchTxtPromo,
  } = useSelector(promotionsSelector);
  const { next, previous } = useSelector(paginationPromotionsSelector);
  const { searchTermPromo } = useSelector(searchSelector);
  const [promotionsId, setPromotionsId] = useState({
    id: null,
    status: '',
  });
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (params.page < 1) {
      dispatch(nullPageParamsPromotions());
    }
  }, [params]);

  useEffect(() => {
    const data = {
      query: { page: params.page || 1 },
    };
    if (!searchTermPromo && !promotions.length) {
      dispatch(
        promotionsFetch({
          data,
          sort: `&sort=status,-id${sort.status.url}`,
          name: searchTermPromo ? `&name=${searchTermPromo}` : searchTermPromo,
        }),
      );
    }
  }, [dispatch, searchTermPromo, searchTxtPromo]);

  useEffect(() => {
    dispatch(nullPageParamsPromotions());
  }, [searchTermPromo]);

  useEffect(() => {
    const data = {
      query: { page: 1 },
    };
    const findPromotions = promotions.find((item) => Number(item.id) === Number(promotionsId.id));
    if (findPromotions) {
      if (findPromotions.status === promotionsId.status) {
        dispatch(
          promotionsFetch({
            data,
            sort: `&sort=status,-id${sort.status.url}`,
            name: searchTermPromo ? `&name=${searchTermPromo}` : searchTermPromo,
          }),
        );
      }
    }
  }, [promotionsId, promotionInfo]);

  const handlerNull = () => {
    dispatch(clearState());
  };

  const pushHandler = () => {
    push('/promotions/create-promotion/');
  };

  const statusHandler = ({ key }) => {
    const data = {
      query: { page: params.page },
    };
    if (Number(params.page) >= 1) {
      data.query.page = 1;
      dispatch(nullPageParamsPromotions());
    }
    if (Number(key) <= 1) {
      dispatch(
        promotionsFetch({
          data,
          sort: `&sort=status,-id&status=${key}`,
          name: searchTermPromo ? `&name=${searchTermPromo}` : searchTermPromo,
        }),
      );
      dispatch(
        sortHandlerPromotionReducer({
          status: {
            id: key,
            title: Number(key) === 0 ? 'sort.active' : 'sort.inactive',
            url: `&status=${key}`,
          },
        }),
      );
    } else {
      dispatch(
        promotionsFetch({
          data,
          sort: `&sort=status,-id&status=`,
          name: searchTermPromo ? `&name=${searchTermPromo}` : searchTermPromo,
        }),
      );
      dispatch(
        sortHandlerPromotionReducer({
          status: {
            id: '2',
            title: '',
            url: '',
          },
        }),
      );
    }
  };

  const handlerNotPush = () => {
    push('/promotions/create-promotion/');
  };
  const onChange = (value, promotionId) => {
    const data = {
      name: 'promotionInfo',
      promotionId,
      newData: { status: value },
    };
    setPromotionsId({
      id: promotionId,
      status: value,
    });
    dispatch(promotionsActiveInactive(data));
    dispatch(loadingActiveInactive({ id: promotionId }));
  };

  const nextBrowserPromotionsHandler = (id) => {
    push(`/promotions/promotion-info/${id}/`);
    const findHome = promotions.find((item) => item.id === Number(id));
    if (findHome) {
      dispatch(addResultsInfoPromotions(findHome));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(searchReducer(''));
  };

  const nextHandler = () => {
    dispatch(addPageParamsPromotions());
    const data = {
      query: { page: params.page + 1 || 1 },
    };
    dispatch(
      promotionsFetch({
        data,
        sort: `&sort=status,-id${sort.status.url}`,
        name: searchTermPromo ? `&name=${searchTermPromo}` : searchTermPromo,
      }),
    );
  };
  const backHandler = () => {
    const data = {
      query: { page: params.page - 1 || 1 },
    };
    dispatch(
      promotionsFetch({
        data,
        sort: `&sort=status,-id${sort.status.url}`,
        name: searchTermPromo ? `&name=${searchTermPromo}` : searchTermPromo,
      }),
    );
    dispatch(minusPageParamsPromotions());
  };

  const handleModalOpen = (id) => {
    setModalOpen(true);
  };

  const closeHandler = () => {
    setModalOpen(false);
  };

  const renderSearchText = () => {
    if (searchTermPromo) {
      return `${t('search.search_promotions')} ${t(sort.status.title)}`;
    }

    if (sort.status.title) {
      return `${t(sort.status.title)} ${t('promotions.name').toLowerCase()}`;
    }

    return t('promotions.name');
  };

  if (getPromotionsLoading) {
    return <SkeletonBlock active={getPromotionsLoading} num={20} titleBool />;
  }

  if (getPromotionsError) {
    return <Errors status={getPromotionsError} />;
  }

  if (searchTermPromo && !promotions.length) {
    return (
      <div className={b()}>
        <div className='block_promotions_title'>
          <p className='title_text_v'>
            {t('search.search_promotions')} {t(sort.status.title).toLowerCase()}
          </p>
          <div>
            <SortBy statusHandler={statusHandler} titleObject={sort} />
          </div>
        </div>
        <NotFoundSearch textSearch={searchTermPromo} />
      </div>
    );
  }

  if (sort.status.title && !promotions.length) {
    return (
      <div className={b()}>
        <div className='block_promotions_title'>
          <p className='title_text_v'>
            {searchTermPromo ? (
              <>
                {t('search.search_promotions')} {t(sort.status.title).toLowerCase()}
              </>
            ) : (
              <>
                {t(sort.status.title)} {t('promotions.name').toLowerCase()}
              </>
            )}
          </p>
          <div>
            <SortBy statusHandler={statusHandler} titleObject={sort} />
          </div>
        </div>
        <NotActiveInactive
          message={
            Number(sort.status.id) === 0 ? t('promotions.no_active') : t('promotions.no_in_active')
          }
          handlerNotPush={handlerNotPush}
          textLink={t('promotions.create_your_promotion')}
        />
      </div>
    );
  }
  if (promotions.length) {
    return (
      <>
        <div className='block_promotions_title'>
          <p className='title_text_v'>{renderSearchText()}</p>
          <div>
            <SortBy statusHandler={statusHandler} titleObject={sort} />
          </div>
        </div>

        <div className={b('space-align-block')}>
          <ShowAllCard
            fileLoading
            results={promotions}
            status
            link='/promotions/create-promotion'
            nextBrowserHandler={nextBrowserPromotionsHandler}
            fileHandler={handlerNull}
            onChange={onChange}
            options={optionsActiveInactive}
            titleFile={t('promotions.create_promotions')}
            textFile={t('promotions.create_a_new_promotions')}
            handleModalOpen={handleModalOpen}
          />
        </div>
        <div className={b('buttons-promotions-styles')}>
          <Button
            className={b('promotions-buttons')}
            type='primary'
            size='large'
            disabled={previous === null}
            onClick={() => backHandler()}
          >
            {t('promotions.back')}
          </Button>
          <Button
            className={b('promotions-buttons')}
            type='primary'
            size='large'
            disabled={next === null}
            onClick={() => nextHandler()}
          >
            {t('promotions.next')}
          </Button>
        </div>
        <ModalComponent onCancel={closeHandler} open={isModalOpen} width={443} height='100%'>
          <ModalAdvertising
            title={t('advertising.want_to_add_promotion')}
            text={t('advertising.promotion_will_be_advertising')}
            buttonYesText={t('challenges.add')}
            noneHandler={closeHandler}
            yesHandler={closeHandler}
          />
        </ModalComponent>
      </>
    );
  }

  return (
    <PlugForLists
      pushHandler={pushHandler}
      subMainText={t('promotions.creating_your_promotions')}
      buttonText={t('promotions.create_your_promotion')}
    />
  );
};

export default Promotions;
