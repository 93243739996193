import React from 'react';

import 'nomadTemplate/_styleTemplate.scss';

const Template = () => {
  return (
    <div className='main'>
      <header>
        <div className='container'>
          <div className='logo'>
            <img src='https://i.ibb.co/wdRkRSZ/Logo.png' alt='logo' />
          </div>
          <ul className='navbar'>
            <li className='navbar_items'>
              <a href='#id1'>О приложении</a>
            </li>
            <li className='navbar_items'>
              <a href='#id2'>О нас</a>
            </li>
            <li className='navbar_items'>
              <a href='#id3'>Челленджи</a>
            </li>
            <li className='navbar_items'>
              <a href='#id4'>Как установить</a>
            </li>
            <li className='navbar_items'>
              <a href='#id5'>Как участвовать</a>
            </li>
            <li className='navbar_items'>
              <a href='#id6'>Партнерам</a>
            </li>
          </ul>
        </div>
      </header>
      <div className='container'>
        <section className='promo'>
          <img className='arrow3' src='https://i.ibb.co/KqY2YK6/path176.png' alt='arrow3' />
          <img className='arrow4' src='https://i.postimg.cc/8z8Wnr2g/path178.png' alt='arrow4' />
          <div className='main-content'>
            <div className='main-content_left'>
              <img src='https://i.ibb.co/WB9cQDY/main-Phone.png' alt='phone' />
            </div>
            <div className='main-content_right'>
              <h1 className='main-page-title'>
                СОЗДАЙ <span className='orange'>СВОЮ</span> ИСТОРИЮ
              </h1>
              <a href='https://play.google.com/store/apps/details?id=com.attractor.nomadapp'>
                <img
                  className='google'
                  src='https://i.ibb.co/XXdTqpG/gplay.png'
                  alt='Google Play Store'
                />
              </a>
              <a href='https://apps.apple.com/kg/app/nomad-sport-app/id1638885667'>
                <img
                  className='apple'
                  src='https://i.ibb.co/k6fpy8L/appstore.png'
                  alt='Apple App Store'
                />
              </a>
            </div>
          </div>
        </section>
      </div>
      <div className='underline' />
      <div className='container'>
        <section className='about_app'>
          <h2 className='orange' id='id1'>
            О приложении
          </h2>
          <div className='about_app-content'>
            <div className='about_app-info'>
              <p>
                <span className='orange'>Nomad App</span> - это приложение для любителей активного
                образа жизни!
              </p>
              <p>
                Функционал приложения, позволяет расширить привычное представление о занятиях
                традиционными видами спорта, такими как: бег, велоспорт, плавание, лыжи, сноуборд,
                скандинавская ходьба и многие другие дисциплины.
              </p>
              <p>
                <span className='orange'>Nomad App</span> - предлагает взглянуть по новому на
                повседневные тренировки и расширить привычное представления о спорте.
              </p>
              <p>
                Одно из уникальных решений продукта - <span className='orange'>Челленджи</span>. Это
                события, которые отображаются в ленте каждого участника. Пользователь регистрируется
                в челлендже, выполняет задание и получает реальные призы.{' '}
              </p>
              <p>
                Это интерактивное наполнение тренировочного процесса и дополнительная мотивация к
                занятиям спортом.{' '}
              </p>
            </div>
            <div className='about_app_img'>
              <img src='https://i.ibb.co/XLvqTv7/phoneApp.png' alt='Nomad App' />
            </div>
          </div>
        </section>
      </div>
      <div className='underline' />
      <div className='container'>
        <section className='about_us'>
          <h2 className='orange' id='id2'>
            О нас
          </h2>
          <div className='about_us_content'>
            <div className='about_us-left'>
              <div className='counters'>
                <p>Более 40 событий</p>
              </div>
              <div className='counters'>
                <p>32 000 + участников</p>
              </div>
              <div className='counters'>
                <p>Более 5 лет опыта</p>
              </div>
            </div>
            <div className='about_us-right'>
              <p>
                Авторы проекта - команда <span className='orange'>Nomad Sport</span>
              </p>
              <p>
                Мы организаторы ярких, масштабных и интересных спортивных мероприятий. Опыт длиною в
                5 лет, позволяет гарантировать высокий уровень сервиса и создавать по настоящему
                исторические события.{' '}
              </p>
              <p>
                Наша миссия - развитие массовых видов спорта и принципов здорового образа жизни в
                Кыргызской Республике.
              </p>
              <p>Наша цель - сделать спорт доступным каждому!</p>
              <p>
                Мы любим свое дело, чтим традиции спортивного сообщества и объединяем здоровой идеей
                более 7000 человек по всей стране.{' '}
              </p>
            </div>
          </div>
          <img className='arrow3' src='https://i.ibb.co/KqY2YK6/path176.png' alt='arrow3' />
          <img className='arrow4' src='https://i.ibb.co/D1XNxrw/path178.png' alt='arrow4' />
        </section>
      </div>
      <div className='underline' />
      <section className='challenge'>
        <div className='container'>
          <div className='challenge_block'>
            <h2 className='orange' id='id3'>
              ЧЕЛЛЕНДЖИ
            </h2>
            <div className='challenge_block_text'>
              <p>
                Это задания, которые отображаются в приложении пользователя. Основное направление -
                это физическая активность: ходьба, бег, велоспорт, плавание и так далее. Задачи
                продуманы под пользователей всех уровней, как для новичков, так и для
                профессиональных спортсменов.{' '}
              </p>
              <p>
                {' '}
                Выполнять челленджи можно в любом месте и в любое время. У каждого события - есть
                дата проведения, поэтому завершить прохождение необходимо до ее окончания.
              </p>
              <p>
                В описании челленджа есть вся необходимая информация: дата проведения, что нужно
                сделать, кто выступает партером события и какой приз можно получить.
              </p>
              <p>
                <span className='orange'>Nomad App</span> работает в паре с трекером{' '}
                <span className='orange'>STRAVA</span> - одно из самых популярных приложений для
                отслеживания тренировок.{' '}
              </p>
            </div>
            <div className='challenge_block_img'>
              <img src='https://i.ibb.co/TYx34Gr/12121-1.png' alt='challenge_block' />
            </div>
          </div>
        </div>
      </section>
      <div className='underline' />
      <div className='container'>
        <section className='install'>
          <h2 className='orange' id='id4'>
            КАК УСТАНОВИТЬ
          </h2>
          <div className='install_descr'>
            <div className='install_descr_text'>
              <p>
                Регистрация профиля в <span className='orange'>Nomad App</span>:
              </p>
              <p id='install_and_dowmload'>1. Скачайте приложение для вашего смартфона</p>
              <p>2. Пройдите регистрацию, чтобы принимать участие в «челленджах»</p>
              <p>
                3. Синхронизируйте свой аккаунт <span className='orange'>STRAVA</span> с приложением{' '}
                <span className='orange'>Nomad App</span>
              </p>
              <p>Подробная информация в видео ролике.</p>
              <a href='https://apps.apple.com/kg/app/nomad-sport-app/id1638885667'>
                <img
                  className='apple_small'
                  src='https://i.ibb.co/k6fpy8L/appstore.png'
                  alt='Apple App Store'
                />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.attractor.nomadapp'>
                <img
                  className='google_small'
                  src='https://i.ibb.co/XXdTqpG/gplay.png'
                  alt='Google Play Store'
                />
              </a>
            </div>
            <div className='install_descr_video'>
              <iframe
                className='youtube_screen'
                src='https://www.youtube.com/embed/_SzDJQfB2nc'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              />
            </div>
          </div>
        </section>
      </div>
      <div className='underline' />
      <div className='container'>
        <section className='install'>
          <h2 className='orange' id='id5'>
            КАК УЧАСТВОВАТЬ
          </h2>
          <div className='install_descr'>
            <div className='install_descr_text'>
              <p>Участие в челленджах:</p>
              <p>
                Мы приготовили простую и понятную инструкцию о том, как принять участие в челлендже.{' '}
              </p>
              <p>
                Все предстоящие события, расположены в отдельной вкладке. С помощью фильтра, вы
                можете выбрать интересующий вид спорта.{' '}
              </p>
              <p>
                Челленджи, в которых вы принимаете участие находятся во вкладке «мой прогресс».
                Задания, которые вы выполнили - в разделе «моя история».{' '}
              </p>
            </div>
            <div className='install_descr_video'>
              <iframe
                title='YouTube video player'
                className='youtube_screen'
                src='https://www.youtube.com/embed/g1k-YMq8LVo'
                allowFullScreen
              />
            </div>
          </div>
        </section>
      </div>
      <div className='underline' />
      <div className='container'>
        <section className='partners_block'>
          <h2 className='orange' id='id6'>
            СОТРУДНИЧЕСТВО
          </h2>
          <div className='partners_block_text'>
            <p>
              Приложение «Nomad App» обладает уникальным функционалом для прямого взаимодействия
              вашего бренда с людьми ведущими активный образ жизни. Это любители и профессиональные
              спортсмены со всего Кыргызстана. Количество пользователей насчитывает более 7000 тысяч
              человек и демонстрирует положительные темпы роста. Аудитория имеет высокие показатели
              по интенсивности занятиями спортом.
            </p>
            <p>
              С помощью ряда уникальных механик мы сможем интегрировать ваш бренд в продукт.
              Основным преимуществом - является прямая лидогенерация. Партнерство с «Nomad App» -
              это отличная возможность по формированию имиджа через активную и здоровую позицию, а
              главное увеличение количества пользователей и интенсивности прямого взаимодействия с
              вашим брендом.
            </p>
            <p>
              Возможности продукта так же позволяют компаниям коммуницировать со своими сотрудниками
              по средствам закрытых, внутрикорпоративных событий. Мы называем это «HR челленджи». С
              их помощь можно увеличить лояльность персонала и применить новые мотивационные
              механики для достижения поставленных целей.{' '}
            </p>
          </div>
          <div className='partners_block_button'>
            <a href='https://forms.gle/xyGEwuhnMVZ77ayv8' className='btn'>
              ПОДАТЬ ЗАЯВКУ
            </a>
          </div>
          <img className='arrow3' src='https://i.ibb.co/KqY2YK6/path176.png' alt='arrow3' />
          <img className='arrow4' src='https://i.ibb.co/D1XNxrw/path178.png' alt='arrow4' />
        </section>
      </div>
      <footer>
        <div className='container'>
          <div className='footer_block'>
            <div className='footer_navigation'>
              <div className='footer_logo'>
                <img src='https://i.ibb.co/wdRkRSZ/Logo.png' alt='logo' />
              </div>
              <ul className='footer_menu'>
                <li className='footer_menu_items'>
                  <a href='#id1'>О приложении</a>
                </li>
                <li className='footer_menu_items'>
                  <a href='#id2'>О нас</a>
                </li>
                <li className='footer_menu_items'>
                  <a href='#id3'>Челленджи</a>
                </li>
                <li className='footer_menu_items'>
                  <a href='#id4'>Как установить</a>
                </li>
                <li className='footer_menu_items'>
                  <a href='#id5'>Как участвовать</a>
                </li>
                <li className='footer_menu_items'>
                  <a href='#id6'>Партнерам</a>
                </li>
              </ul>
            </div>
            <div className='footer_contacts'>
              <h2>Свяжись с нами</h2>
              <div className='phone_number'>
                <img src='https://i.ibb.co/qdm4T9d/phonecall.png' alt='Phone Number' />
                <p>+996 998 20 30 70</p>
              </div>
              <div className='email'>
                <img src='https://i.ibb.co/C5Zbsxt/mailIcon.png' alt='Email' />
                <p>sales@nomadsport.kg</p>
              </div>
              <h2 className='footer_support'>Тех поддержка</h2>
              <div className='support'>
                <img src='https://i.ibb.co/C5Zbsxt/mailIcon.png' alt='Support' />
                <p>supportapp@nomadsport.kg</p>
              </div>
            </div>
            <div className='footer_social'>
              <h2>Follow us</h2>
              <div className='footer_social_list'>
                <div className='social_icons'>
                  <a href='https://ru-ru.facebook.com/nomadsportkg/'>
                    <img src='https://i.ibb.co/gdksWwX/facebook.png' alt='facebook' />
                  </a>
                </div>
                <div className='social_name'>
                  <p>Facebook</p>
                </div>
              </div>
              <div className='footer_social_list'>
                <div className='social_icons'>
                  <a href='https://www.instagram.com/nomadsportkg/'>
                    <img src='https://i.ibb.co/fNwHtJN/instagram.png' alt='instagram' />
                  </a>
                </div>
                <div className='social_name'>
                  <p>Instagram</p>
                </div>
              </div>
              <div className='footer_social_list'>
                <div className='social_icons'>
                  <a href='https://www.youtube.com/channel/UCqLEqotyAhWUg9VCNekXsGA'>
                    <img src='https://i.ibb.co/3hPZ8CS/youtube.png' alt='youtube' />
                  </a>
                </div>
                <div className='social_name'>
                  <p>YouTube</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer_block_store'>
          <a href='https://play.google.com/store/apps/details?id=com.attractor.nomadapp'>
            <img
              className='footer_store'
              src='https://i.ibb.co/XXdTqpG/gplay.png'
              alt='Google Play Store'
            />
          </a>
          <a href='https://apps.apple.com/kg/app/nomad-sport-app/id1638885667'>
            <img
              className='footer_store'
              src='https://i.ibb.co/k6fpy8L/appstore.png'
              alt='Apple App Store'
            />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Template;
