import { CloseOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form, message, Switch } from 'antd';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import teamAvatar from 'assets/images/icons/teamAvatar.svg';
import noAvatar from 'assets/images/photos/no_avatar.png';
import BreadcrumbsComponent from 'components/BreadcrumbsComponent/BreadcrumbsComponent';
import DownloadPhoto from 'components/DownloadPhoto/DownloadPhoto';
import FormField from 'components/FormField/FormField';
import { messagesErrorFetch } from 'components/Messages/Messagetext';
import { errorsValidateReducer } from 'redux/historyNotifications/historyNotifications';
import {
  clearCreateNews,
  createNewsAddTeam,
  createNewsAddUser,
  createNewsReducer,
  deleteCreatePhoto,
  newsCreate,
  newsCreatePhotoDownload,
  newsSelector,
  removeTeamReducer,
  removeUserReducer,
} from 'redux/news/newsSlice';
import { teamsFetch, teamsSelector } from 'redux/teams/teamsSlice';
import { usersFetch, usersSelector } from 'redux/users/usersSlice';
import { checkHandlerFor, deleteEmptyQueryStrings, filesValidateImg } from 'utils/constants';
import filesValidate from 'utils/filesValidate/filesValidate';
import localesUtils from 'utils/localesUtils/localesUtils';

import localesValidateInput from 'utils/localiesValidateInput/localiesValidateInput';

const Creating = () => {
  const b = bem('Creating');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { createNews, newsSuccess, newsInfo } = useSelector(newsSelector);
  const [value, setValue] = useState(null);
  const [search, setSearch] = useState('');
  const [targeted, setTargeted] = useState(false);
  const { users, usersLoading } = useSelector(usersSelector);
  const { results, teamsLoading } = useSelector(teamsSelector);
  const push = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(clearCreateNews());
    };
  }, [dispatch]);

  useEffect(() => {
    if (newsSuccess.success !== 'not') {
      if (newsInfo) {
        push(`/news/news-info/${newsInfo?.results?.id}/`);
      }
    }
  }, [newsSuccess]);

  useEffect(() => {
    const queryObj = {
      search,
      name: search,
      size: 5,
    };

    const validateQuery = deleteEmptyQueryStrings(queryObj);
    const data = {
      query: validateQuery,
    };
    const delay = setTimeout(() => {
      if (search) {
        dispatch(usersFetch({ data }));
        dispatch(teamsFetch({ data }));
      }
    }, 500);
    return () => clearTimeout(delay);
  }, [dispatch, search]);

  const formHandler = (e) => {
    const { target } = e;
    const dataName = target.dataset.name;
    dispatch(createNewsReducer({ [dataName]: target.value }));
    if (target.value) {
      dispatch(errorsValidateReducer(true));
    }
  };

  const photosSaveHandler = (e) => {
    const { files } = e.target;
    if (filesValidateImg(files).length) {
      const ret = filesValidate(files, createNews.images);
      checkHandlerFor(newsCreatePhotoDownload, ret);
    }
  };

  const deletePhotoHandler = (id) => {
    dispatch(deleteCreatePhoto(id));
  };

  const handleSearch = (e) => {
    setValue(e);
    setSearch(e?.toLowerCase());
  };

  const handleSelect = (option) => {
    const { user, team } = option;
    if (user) {
      if (createNews.users.find((item) => item.id === user.id)) {
        return message.error(messagesErrorFetch(t('errors.this_user_already_added')));
      }
      dispatch(createNewsAddUser({ id: user.id, email: user.email }));
    } else if (team) {
      if (createNews.teams.find((item) => item.id === team.id)) {
        return message.error(messagesErrorFetch(t('errors.this_team_already_added')));
      }
      dispatch(createNewsAddTeam({ id: team.id, name: team.name }));
    }
    setValue(null);
    dispatch(errorsValidateReducer(true));
  };

  const onFinish = () => {
    const obj = {
      ...createNews,
    };

    delete obj.images;
    delete obj.users;
    delete obj.teams;

    createNews.images.forEach((item, i) => {
      obj[`images[${i}]image`] = item;
    });

    const formData = new FormData();
    for (const name in obj) {
      if (name) {
        formData.append(name, obj[name]);
      }
    }
    dispatch(newsCreate({ data: formData }));
  };

  return (
    <div className={b()} data-testid='news--form-element'>
      <div className='breadcrumbs'>
        <BreadcrumbsComponent
          linkProps
          componentName='news.name'
          pushHandler='/news'
          textEnd={t('news.news_creation')}
        />
        <p className={b('title')}>{t('news.news_creation')}</p>
        <p className={b('text')}>{t('news.create_new_news')}</p>
        <DownloadPhoto
          isNews
          photos={createNews.images}
          maxPhotos={0}
          deletePhotoHandler={deletePhotoHandler}
          photosSaveHandler={photosSaveHandler}
          title={false}
        />
        <div className={b('target-block')} style={{ display: 'none' }}>
          <p className={b('target-label')}>{t('news.targeted_send')}</p>
          <Switch checked={targeted} onChange={() => setTargeted(!targeted)} />
        </div>
        {targeted ? (
          <>
            <label className={b('autocomplete_label')} htmlFor='autocomplete'>
              {t('teams.add_participant')}
            </label>
            <Form.Item data-testid='autocomplete-users'>
              <AutoComplete
                id='autocomplete'
                loading={usersLoading || teamsLoading}
                value={value}
                style={{ width: '100%' }}
                onSearch={handleSearch}
                onSelect={(e, option) => handleSelect(option)}
                placeholder={t('news.input_email_or_team')}
              >
                {users?.items?.map((user) => (
                  <AutoComplete.Option
                    key={user.id}
                    value={user.email}
                    style={{ padding: 0 }}
                    user={user}
                  >
                    <div className={b('option')}>
                      <img
                        src={user?.avatar_filename ? user.avatar_filename : noAvatar}
                        alt='avatar'
                      />
                      <div>
                        <p className={b('option_label')}>{t('news.participant')}</p>
                        <p className={b('option_info')}>{user.email}</p>
                      </div>
                    </div>
                  </AutoComplete.Option>
                ))}
                {results?.items?.map((team) => (
                  <AutoComplete.Option
                    key={team.id}
                    value={team.name}
                    style={{ padding: 0 }}
                    team={team}
                  >
                    <div className={b('option')}>
                      <img src={teamAvatar} alt='avatar' />
                      <div>
                        <p className={b('option_label')}>{t('news.team')}</p>
                        <p className={b('option_info')}>{team.name}</p>
                      </div>
                    </div>
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </Form.Item>
            <div className={b('participants_block')}>
              {createNews.users.map((user) => (
                <div className={b('participant')} key={user.id}>
                  <div className={b('added_info')}>
                    <span className={b('added_label')}>{t('news.participant')}</span>
                    <span>{user.email}</span>
                  </div>
                  <CloseOutlined
                    style={{ cursor: 'pointer', fontSize: '14px' }}
                    onClick={() => dispatch(removeUserReducer(user.id))}
                  />
                </div>
              ))}
              {createNews.teams?.map((team) => (
                <div className={b('participant')} key={team.id}>
                  <div className={b('added_info')}>
                    <span className={b('added_label')}>{t('news.team')}</span>
                    <span>{team.name}</span>
                  </div>
                  <CloseOutlined
                    style={{ cursor: 'pointer', fontSize: '14px' }}
                    onClick={() => dispatch(removeTeamReducer(team.id))}
                  />
                </div>
              ))}
            </div>
          </>
        ) : null}
        <Form form={form} name='basic' layout='vertical' onChange={formHandler} onFinish={onFinish}>
          <FormField
            type='modificationInput'
            label='news.news_title'
            name='title'
            message={localesUtils(
              'Пожалуйста, введите название новости',
              'Please input your news title',
            )}
            rules={[
              {
                max: 40,
                message: localesValidateInput(
                  <span>
                    {localesUtils(
                      'Название не должно содержать больше 40 символов',
                      'Title should be less than 40 symbols',
                    )}
                  </span>,
                  'promotionsDescriptionSecond',
                  localesUtils(
                    'Название не должно содержать больше 40 символов',
                    'Title should be less than 40 symbols',
                  ),
                ),
              },
            ]}
            dataName='title'
            placeholder='news.news_title'
          />
          <FormField
            type='modificationInput'
            maxLength={150}
            label='news.news_description'
            name='text'
            message={localesUtils(
              'Пожалуйста, введите описание новости',
              'Please input your news text',
            )}
            rules={[
              {
                max: 150,
                message: localesValidateInput(
                  <span>
                    {localesUtils(
                      'Описание не должно содержать больше 150 символов',
                      'Text should be less than 150 symbols',
                    )}
                  </span>,
                  'promotionsDescriptionSecond',
                  localesUtils(
                    'Описание не должно содержать больше 150 символов',
                    'Text should be less than 150 symbols',
                  ),
                ),
              },
            ]}
            dataName='text'
            placeholder='news.news_description'
          />
          <Form.Item
            className={b('btn_form')}
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button
              disabled={
                !createNews.title ||
                !createNews.text ||
                (targeted && !createNews.users.length && !createNews.teams.length)
              }
              data-testid='button-submit'
              style={{
                width: 129,
                height: 44,
                borderRadius: 8,
              }}
              type='primary'
              htmlType='submit'
              loading={newsSuccess.loading}
            >
              {t('news.news_create_button')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Creating;
