import { arrayMove } from '@dnd-kit/sortable';
import bem from 'easy-bem';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AdvertisingHeader from 'components/AdvertisingHeader/AdvertisingHeader';
import DragAndDrop from 'components/DragAndDrop/DragAndDrop';
import FormField from 'components/FormField/FormField';
import ModalAdvertising from 'components/UI/ModalAdvertising/ModalAdvertising';
import ModalComponent from 'components/UI/ModalComponent/ModalComponent';
import {
  challengesCategoriesFetch,
  challengesSelector,
} from 'redux/challengesSlice/challengesSlice';

import { dateEnd, dateFormat } from 'utils/dateFormat/dateFormat';
import { optionsActiveInactive } from 'utils/sortStatus/sortStatus';

const starts = [
  {
    id: 1,
    title: 'Название старта 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium amet, deleniti dolorem doloremque nobis nostrum optio perferendis praesentium quasi qui quos saepe sint vitae! Dolor est fugiat laboriosam molestiae quam!',
    starts_at: '2023-04-10',
    ends_at: '2023-05-10',
    images: [
      {
        image:
          'https://s3.nomad.attractor-software.com/dev-mediafiles/news/51/3550/imresizer-1675759468240.png',
      },
    ],
    category: {
      id: 7,
      name: 'Ходьба',
      icon: 'https://s3.nomad.attractor-software.com/dev-mediafiles/categories/7/Walking.svg',
    },
    order: 1,
    status: 0,
  },
  {
    id: 2,
    title: 'Название старта 2',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium amet, deleniti dolorem doloremque nobis nostrum optio perferendis praesentium quasi qui quos saepe sint vitae! Dolor est fugiat laboriosam molestiae quam!',
    starts_at: '2023-04-10',
    ends_at: '2023-05-10',
    images: [
      {
        image:
          'https://s3.nomad.attractor-software.com/dev-mediafiles/challenges/873/3511/runner-wallpaper-1600x900.jpg',
      },
    ],
    category: {
      id: 7,
      name: 'Ходьба',
      icon: 'https://s3.nomad.attractor-software.com/dev-mediafiles/categories/7/Walking.svg',
    },
    order: 2,
    status: 0,
  },
  {
    id: 3,
    title: 'Название старта 3',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium amet, deleniti dolorem doloremque nobis nostrum optio perferendis praesentium quasi qui quos saepe sint vitae! Dolor est fugiat laboriosam molestiae quam!',
    starts_at: '2023-04-10',
    ends_at: '2023-05-10',
    images: [
      {
        image: 'https://s3.nomad.attractor-software.com/dev-mediafiles/challenges/872/3510/.jpeg',
      },
    ],
    category: {
      id: 7,
      name: 'Ходьба',
      icon: 'https://s3.nomad.attractor-software.com/dev-mediafiles/categories/7/Walking.svg',
    },
    order: 3,
    status: 0,
  },
];

const AdvertisingStarts = () => {
  const b = bem('Advertising');
  const { t } = useTranslation();
  const { categories } = useSelector(challengesSelector);
  const dispatch = useDispatch();
  const [modeActive, setModeActive] = useState(1);
  const [items, setItems] = useState([...starts]);
  const [dragMode, setDragMode] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const columns = [
    {
      title: '№',
      dataIndex: 'order',
      width: '3%',
      render: (text, record) => <p className={b('table_order')}>{record.order}</p>,
    },
    {
      title: t('starts.title_label'),
      dataIndex: 'title',
      width: '20%',
      render: (text, record) => (
        <div className={b('table_title')}>
          <div className={b('table_image')}>
            <img src={record.images[0].image} alt={record.title} />
          </div>
          <span>{record.title}</span>
        </div>
      ),
    },
    {
      title: t('starts.description_label'),
      dataIndex: 'description',
      width: '25%',
      render: (text, record) => <p className={b('table_description')}>{record.description}</p>,
    },
    {
      title: t('challenges.categories'),
      dataIndex: 'category',
      render: (text, record) => (
        <div className={b('table_category')}>
          <img src={record.category.icon} width={24} height={24} alt={record.category.name} />
          <p>{record.category.name}</p>
        </div>
      ),
    },
    {
      title: t('advertising.start_date'),
      dataIndex: 'starts_at',
      render: (text, record) => (
        <span className={b('table_date')}>{dateFormat(record.starts_at)}</span>
      ),
    },
    {
      title: t('advertising.end_date'),
      dataIndex: 'ends_at',
      render: (text, record) => (
        <span className={b('table_date')}>{dateFormat(record.ends_at)}</span>
      ),
    },
    {
      title: t('home.statuses'),
      dataIndex: 'status',
      render: (text, record) => (
        <div className={b('block_category_active')}>
          <FormField
            disabled={!dateEnd(record.ends_at)}
            type='selectStatus'
            options={optionsActiveInactive}
            size={20}
            value={record.status}
            id={record.id}
          />
        </div>
      ),
    },
  ];

  if (dragMode) {
    columns.unshift({
      key: 'sort',
      width: '3%',
    });
  }

  useEffect(() => {
    if (!categories.results.length) {
      dispatch(challengesCategoriesFetch());
    }
  }, [dispatch]);

  const changeModeHandler = (mode) => {
    setModeActive(mode);
  };

  const cancelDragMode = () => {
    setDragMode(false);
    setItems([...starts]);
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (active.id !== over.id) {
      setItems((prev) => {
        const activeIndex = items.findIndex((item) => item.id === active.id);
        const overIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(prev, activeIndex, overIndex).map((item, index) => ({
          ...item,
          order: index + 1,
        }));
      });
    }
  };

  const openModalHandler = (id) => {
    setModalOpen(true);
    setActiveId(id);
  };

  const closeHandler = () => {
    setModalOpen(false);
    setActiveId(null);
  };

  const yesHandler = () => {
    setModalOpen(false);
    setItems(
      items
        .filter((item) => item.id !== activeId)
        .map((item, index) => ({ ...item, order: index + 1 })),
    );
    setActiveId(null);
  };

  return (
    <div className={b()}>
      <AdvertisingHeader
        title='starts.name'
        hasCategories
        modeActive={modeActive}
        changeMode={changeModeHandler}
        changeDragMode={() => setDragMode(!dragMode)}
        dragMode={dragMode}
        cancelDrag={cancelDragMode}
      />
      <DragAndDrop
        handleDragEnd={handleDragEnd}
        items={items}
        columns={columns}
        dragMode={dragMode}
        modeActive={modeActive}
        handleModalOpen={openModalHandler}
      />
      <ModalComponent onCancel={closeHandler} open={isModalOpen} width={443} height='100%'>
        <ModalAdvertising
          title={t('advertising.want_to_remove_start')}
          text={t('advertising.start_will_not_be_advertising')}
          buttonYesText={t('advertising.remove')}
          noneHandler={closeHandler}
          yesHandler={yesHandler}
        />
      </ModalComponent>
    </div>
  );
};

export default AdvertisingStarts;
